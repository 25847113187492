$blue: #06405e
$grey: #e8e8e8
$sans-font: 'gidoleregular', sans-serif
$serif-font: "kepler-std", serif
$small: 500px

*, *::before, *::after
	box-sizing: border-box
	margin: 0
	padding: 0

body
	font-family: $sans-font
	font-size: 16px
	line-height: 1.1em
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	text-rendering: optimizeLegibility
	background-color: $blue
	color: $grey
	overflow: hidden

main
	width: 96%
	max-width: 1800px
	padding: 0 4%
	margin: 0 auto
	position: relative
	z-index: 2

.logomark
	position: fixed
	width: 310px
	height: 220px
	pointer-events: none
	bottom: 0
	right: 0
	z-index: 1
	@media screen and (min-width: $small)
		width: 400px
		height: 310px
	
	img
		overflow: hidden	
		position: relative
		top: 2rem
		left: 2rem
		width: 100%
		@media screen and (min-width: $small)
			top: 2.25rem

ul
	list-style-type: none
	margin: 0
	padding: 0
	li
		margin: 0
		padding: 0
		color: white

a
	color: inherit
	text-decoration: none
	transition: color 200ms ease-out
	&:hover
		color: darken($grey,6%)

ul.address
	max-width: 430px
	padding-top: 5.5rem
	border-top: 12px solid $grey
	li
		border-top: 1px solid $grey
		font-size: 1.05rem
		padding: .8rem 0
		line-height: 1.35em

span.detail
	display: block
	position: relative
	&.left
		border-bottom: 1px solid $grey
		padding-bottom: 0.8rem
		&::after
			content: '/'
			display: none
			padding: 0 8px 0 10px
	&.right
		padding-top: 0.8rem
	
	i
		font-family: $serif-font
		font-size: 1.1rem
	
	@media screen and (min-width: $small)
		&.left, &.right
			padding: 0
			border: 0
			display: inline-block
		
		&.left::after
			display: inline-block